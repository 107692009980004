import $ from 'jquery';
import './css/main.scss';
import axios from 'axios'
document.addEventListener('DOMContentLoaded', function () {
    document.getElementsByTagName('html')[0].style.fontSize = window.innerWidth + 'px', false
    $('form').submit(function (e) {
        e.preventDefault()
    })
})
var numberReg = /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/

function addOnLoadEvent(func) {
    var oldLoad = window.onload;
    if (typeof window.onload != "function") {
        window.onload = func;
    }
    else
        window.onload = function () {
            oldLoad();
            func();
        }
}

function handleSubmit() {
    var target = document.getElementById('submit');
    var data = {};
    target.addEventListener('click', function (event) {
        var name = document.getElementsByTagName('input')[0].value;
        var phone = document.getElementsByTagName('input')[1].value;
        var company = document.getElementsByTagName('input')[2].value;
        var email = document.getElementsByTagName('input')[3].value;
        var business = document.getElementsByTagName('input')[4].value;
        var channel = document.getElementsByTagName('input')[5].value;
        var requirements = document.getElementById('requirements').value;

        if(!numberReg.test(phone)){
            alert('请输入正确的11位手机号')
            return ;
        }

        data.name = name;
        data.company = company;
        data.business = business;
        data.phone = phone;
        data.requirements = requirements;
        data.email = email;
        data.channel = channel;

        axios.post('http://www.pengbomob.com:8089/collectData', data)
            .then(function (response) {
                if (response.data['code'] == 1) {
                    alert('成功提交信息')
                }
                else if(response.data['code'] == 0){
                    alert(response.data['message'])
                }
            })
            .catch(function (error) {
                console.log(error);
            });

        /* 模拟api功能 */

        // axios.get('https://www.fastmock.site/mock/dff18a92c5ac756ee35c0381951b0b2b/collectData/api/test', data)
        //     .then(function (response) {
        //         console.log(response)
        //         // if (response.data['code'] == 1) {
        //         if (response.data['code'] == 0) {
        //             alert(response.data['message']);
        //             document.addEventListener('submit',function(e){
        //                 e.returnValue=value;
        //             })
        //         }
        //     })
        //     .catch(function (error) {
        //         console.log(error);
        //     });
    })
}
/* 导航页跳转 */
function handleNavClick() {
    $('header ul li:nth-child(1)').click(function () {
        window.location.hash = '#about'
    });
    $('header ul li:nth-child(2)').click(function () {
        window.location.hash = '#concept'
    });
    $('header ul li:nth-child(3)').click(function () {
        window.location.hash = '#advantage'
    });
    $('header ul li:nth-child(4)').click(function () {
        window.location.hash = '#contact'
    });
}
addOnLoadEvent(handleSubmit);
addOnLoadEvent(handleNavClick);
